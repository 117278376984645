import {Login} from "./Login";
import React, {useEffect} from "react";
import {Index} from "./Data";

export function Root() {
    const [password, setPassword] = React.useState("");

    useEffect(() => {

    }, []);

    return <>
        {!password ?
            <Login password={password} setPassword={setPassword} /> :
            <Index password={password} clearPassword={() => {
                setPassword("");
            }}/>
        }
    </>
}