import React, {useRef} from "react";
import { ClayPanel } from "./ClayPanel";

export function ClayInput({
    value,
    onChange,
    className,
    placeholder,
    autoFocus,
    disabled,
    ...props
}: {
    value: string;
    onChange: (arg: string) => void;
    className?: string;
    placeholder?: string;
    autoFocus?: boolean;
    disabled?: boolean;
}) {
    const inputRef = useRef<HTMLInputElement>(null);

    return <ClayPanel
        backgroundColor={"bg-darker-background"}
        borderColor={"bg-border-color hover:border-hover-border-color focus-within:border-active-border-color"}
        onClick={() => {
            inputRef.current!.focus();
        }}
    >
        <input type={"text"}
               className={"bg-transparent border-0 outline-0 w-full h-full px-4 py-2 text-main"}
               value={value} ref={inputRef}
               onChange={e => {
                   onChange(e.target.value);
               }}
               placeholder={placeholder}
               autoFocus={autoFocus}
               disabled={disabled}
        />
    </ClayPanel>
}