import {Divider} from "../clay/Divider";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {ClayPanel} from "../clay/ClayPanel";
import {ClayButton} from "../clay/ClayButton";
import {readableByteSize} from "../../util/dataformat";

interface ObjectInfo {
    type: string;
    instances: number;
    bytes: number;
    order: number;
}

interface ThreadInfo {
    tid: number;
    priority: number;
    name: string;
    state: string;
    native: boolean;
    isSuspended: boolean;
    isDaemon: boolean;
    stacktrace: string[];
    lockedMonitors: string[];
    lockedSynchronizers: string[];
}

interface ClientInfo {
    time: number;
    version: string;
    name: string;
    playerName: string;
    playerUUID: string;
    deadlockedThreads: string[];
    pid: number;
    threadCount: number;
    clayCoreVersion: string;
    clientInfo: Record<string, string>;
    renderThread: ThreadInfo;
    composeThread: ThreadInfo;
    memory: ObjectInfo[];
}
export function Index({ password, clearPassword }: { password: string; clearPassword: () => void; }) {
    const [info, setInfo] = useState<ClientInfo | undefined>(undefined);
    const [error, setError] = useState<string | undefined>(undefined);
    const [isLoading, setIsLoading] = useState(false);
    const downloadLinkRef = useRef<HTMLAnchorElement>(null)

    const load = useCallback(() => {
        setIsLoading(true);
        fetch("http://127.0.0.1:8323/api/info", {
            headers: {
                "Authorization": password,
            }
        })
            .then(async res => {
                setIsLoading(false);
                if (res.ok) {
                    setError(undefined);
                    let data = await res.json()
                    setInfo(data);
                    console.log(data)
                } else {
                    console.log(res);
                    setError(await res.text());
                }
            })
            .catch(err => {
                setIsLoading(false);
                console.log(err);
                setError(err.message);
            })
    }, [password]);

    const exportFile = () => {
        const blob = new Blob([JSON.stringify(info, undefined, 4)], { type: 'text/plain;charset=utf-8' });
        const url = URL.createObjectURL(blob);
        const filename = `claycore-debugger-${info?.name}-${info?.time}.json`;

        const link = downloadLinkRef.current!;
        link.href = url;
        link.download = filename;
        link.click();
    }

    useEffect(() => {
        load();
    }, [load]);

    return <div className={"p-4 w-full flex items-center justify-center"}>
        <a className={"hidden"} ref={downloadLinkRef}>下载</a>
        <ClayPanel className={"mt-[20vh] max-w-[80vw] min-w-[40vw]"}>
            <div className={"p-4"}>
                <div className={"text-lg"}>欢迎使用 ClayCore 调试工具™</div>
                <div className={"text-sm opacity-60"}>Welcome to use ClayCore Debugger Tool™ V1.0</div>
                <Divider className={"my-4"}/>
                <div className={"space-y-4"}>
                    {error && <div className={"flex gap-2 items-center text-sm opacity-80 text-clay-red"}>
                        <div className={"w-2 h-2 bg-clay-red"}></div>
                        加载数据失败 {error}
                    </div>}
                    {info && <>
                        <div className={"text-sm"}>
                            时间：{new Date(info.time).toLocaleString()}
                        </div>
                        <div className={"text-sm"}>
                            版本：{info.version} - ClayCore {info.clayCoreVersion}
                        </div>
                        <div className={"text-sm"}>
                            玩家：{info.playerName} {info.playerUUID} in {info.name}
                        </div>
                        <Divider/>
                        <div className={"text-sm"}>
                            死锁（线程 x {info.threadCount}）：{info.deadlockedThreads?.join(", ") ?? "无"}
                        </div>
                        <Divider/>
                        <ThreadInfoDisplay name={"渲染线程"} thread={info.renderThread}/>
                        <Divider/>
                        <ThreadInfoDisplay name={"UI线程"} thread={info.composeThread}/>
                        <Divider/>
                        <ClientInfoDisplay data={info.clientInfo}/>
                        <Divider/>
                        {info.memory && <ObjectInfoDisplay data={info.memory}/>}
                    </>}
                </div>
                <Divider className={"my-4"}/>
                <div className={"flex gap-2 justify-end"}>
                    <ClayButton onClick={clearPassword} color={"bg-clay-brand-red"}>注销</ClayButton>
                    <ClayButton onClick={exportFile} color={"bg-clay-green"}>导出</ClayButton>
                    <ClayButton onClick={() => {
                        load();
                    }} disabled={isLoading}>重新加载</ClayButton>
                </div>
            </div>
        </ClayPanel>
    </div>
}

function ThreadInfoDisplay({ name, thread }: { name: string; thread: ThreadInfo }) {
    const [showStacktrace, setShowStacktrace] = useState(false);

    if (!thread) {
        return <div>
            <div className={"text-sm opacity-60 text-clay-red"}>线程 {name} 未启动</div>
        </div>
    }


    return <div className={"text-sm"}>
        <div className={"text-clay-green"}>线程 {name}</div>
        <div className={"opacity-60"}>tid {thread.tid} - {thread.name} - {thread.state}</div>
        <div className={"opacity-60"}>priority = {thread.priority} {thread.isSuspended && "suspended"} {thread.native && "isNative"} {thread.isDaemon && "isDaemon"}</div>
        <div className={"opacity-80 hover:opacity-100"} onClick={() => {
            setShowStacktrace(!showStacktrace);
        }} >
            Stacktrace: {showStacktrace ? "显示" : "隐藏"}
        </div>
        {showStacktrace && <div className={"p-4 my-2 bg-black rounded font-mono"}>
            {thread.stacktrace.map((line, index) => {
                return <div key={index}>{line}</div>
            })}
        </div>}
        {thread.lockedMonitors.length > 0 && <StringArrayInfo name={"锁"} data={thread.lockedMonitors}/>}
        {thread.lockedSynchronizers.length > 0 && <StringArrayInfo name={"同步锁"} data={thread.lockedSynchronizers}/>}
    </div>;
}

function ClientInfoDisplay({ data }: { data: Record<string, string> }) {
    const [show, setShow] = useState(false);

    return <div className={"text-sm"}>
        <div className={"opacity-80 hover:opacity-100"} onClick={() => {
            setShow(!show);
        }} >
            客户端信息: {show ? "显示" : "隐藏"}
        </div>
        {show && <div className={"p-4 my-2 bg-black rounded font-mono"}>
            {Object.entries(data).map(([key, value], index) => {
                return <div key={index}>{key} = {value}</div>
            })}
        </div>}
    </div>;
}


function StringArrayInfo({ name, data }: { name: string; data: string[] }) {
    const [show, setShow] = useState(false);

    return <div className={"text-sm"}>
        <div className={"opacity-80 hover:opacity-100"} onClick={() => {
            setShow(!show);
        }} >
            {name}: {show ? "显示" : "隐藏"}
        </div>
        {show && <div className={"p-4 my-2 bg-black rounded font-mono"}>
            {data.map((line, index) => {
                return <div key={index}>{line}</div>
            })}
        </div>}
    </div>;
}

function ObjectInfoDisplay({ data }: { data: ObjectInfo[] }) {
    const [show, setShow] = useState(false);

    return <div className={"text-sm"}>
        <div className={"opacity-80 hover:opacity-100"} onClick={() => {
            setShow(!show);
        }} >
            内存信息: {show ? "显示" : "隐藏"}
        </div>
        {show && <div className={"p-4 my-2 bg-black rounded font-mono"}>
            {data.filter(it => !(it.instances < 50 && it.bytes < 10 * 1024)).map((info, index) => {
                return <div key={index}>
                    <span className="text-clay-cyan">{info.type}</span> x <span className={"text-clay-blue"}>{info.instances}</span> = {readableByteSize(info.bytes)}
                </div>
            })}
        </div>}
    </div>;
}
