import {ReactNode} from "react";
import classNames from "classnames";

export function ClayPanel({
    backgroundImage,
    hoverable,
    border,
    children,
    borderAlpha,
    borderColor,
    className,
    backgroundColor,
    contentClassName,
    ...props
}: {
    backgroundImage?: string,
    className?: string;
    borderColor?: string;
    borderAlpha?: number;
    contentClassName?: string;
    backgroundColor?: string;
    hoverable?: boolean;
    border?: boolean;
    children: ReactNode;
    [props: string]: any;
}) {
    if (backgroundImage) {
        return <div className={classNames("relative", className)} {...props}>
            <div className="w-full h-full corner-clip absolute mt-shadow-size bg-shadow"></div>
            <div className="corner-clip bg-blend-hard-light bg-cover bg-no-repeat bg-center"
                 style={{
                     background: `linear-gradient(0deg, rgba(220, 220, 230, ${borderAlpha ?? 0.3}), rgba(220, 220, 230, ${borderAlpha ?? 0.3})), url(${backgroundImage})`,
                 }}>
                <div
                    className={classNames("w-full h-full corner-clip-content p-border-size bg-clip-content bg-background text-main-font bg-cover bg-no-repeat bg-center", contentClassName)}
                    style={{
                        background: `url(${backgroundImage})`
                    }}
                >
                    {children}
                </div>
            </div>
        </div>
    } else {
        return <div className={classNames("relative", className)} {...props}>
            <div className="w-full h-full corner-clip absolute mt-shadow-size bg-shadow"></div>
            {(border ?? true) ? <div className={classNames("w-full h-full p-border-size corner-clip", borderColor ?? "bg-border-color")}>
                <div
                    className={classNames("w-full h-full corner-clip text-main-font bg-cover bg-no-repeat bg-center", backgroundColor ?? "bg-background", contentClassName)}>
                    {children}
                </div>
            </div> : <div
                className={classNames("w-full h-full corner-clip text-main-font bg-cover bg-no-repeat bg-center", backgroundColor ?? "bg-background", contentClassName)}>
                {children}
            </div>}
        </div>
    }
}