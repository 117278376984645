export function readableByteSize(
  size: number,
  digits: number = 1
): [string, string] {
  if (size === 0) return ["0", "B"];
  let i = byteSizeLevel(size);

  let number = (size / Math.pow(1024, i)).toFixed(digits);
  if (number.endsWith(".0")) number = number.substring(0, number.length - 2);

  return [number, readableByteSizeLevel(i)];
}

export function byteSizeLevel(size: number): number {
  if (size === 0) return 0;
  return Math.floor(Math.log(size) / Math.log(1024));
}

export function readableByteSizeLevel(level: number): string {
  return ["B", "KB", "MB", "GB", "TB"][level];
}

export function convertByteSize(
  size: number,
  level: number,
  digits: number = 1
): [string, string] {
  return [
    (size / Math.pow(1024, level)).toFixed(digits),
    readableByteSizeLevel(level),
  ];
}
