import classNames from "classnames";
import './ClayButton.css';
import {ReactNode} from "react";

export function ClayButton({
    disabled,
    children,
    className,
    color,
    onClick,
}: {
    className?: string;
    color?: string;
    disabled?: boolean;
    onClick?: () => void;
    children?: ReactNode;
}) {
    return <div className={classNames("btn-parent", className, {
        "btn-parent-disabled": disabled
    })} onClick={e => {
        if (!disabled && onClick) {
            onClick();
        }
    }}>
        <div className="btn-shadow corner-clip"></div>
        <div className="btn-frame corner-clip"></div>
        <div className={classNames("btn-content corner-clip clay-text-shadow", disabled ? "bg-clay-dark-gray" : (color ?? "bg-clay-cyan"))}>
            {children}
        </div>
        <div className="btn-thick"></div>
        <div className="btn-highlight"></div>
    </div>
}