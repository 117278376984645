import {ClayPanel} from "../clay/ClayPanel";
import React, {useEffect} from "react";
import {ClayInput} from "../clay/ClayInput";
import {Divider} from "../clay/Divider";
import {ClayButton} from "../clay/ClayButton";
import {Icon} from "@iconify/react";

interface PingData {
    version: string;
    player: string;
}

export function Login({
    password,
    setPassword,
}: {
    password: string,
    setPassword: (password: string) => void,
}) {
    const [unauthorizedPassword, setUnauthorizedPassword] = React.useState("");
    const [pingData, setPingData] = React.useState<PingData | undefined>(undefined);
    const [pingError, setPingError] = React.useState<string | undefined>(undefined);
    const [authorizing, setAuthorizing] = React.useState(false);
    const [authorizationError, setAuthorizationError] = React.useState("");

    const ping = () => {
        if (document.hidden && document.visibilityState === "hidden" && !document.hasFocus()) {
            return;
        }

        fetch("http://127.0.0.1:8323/ping")
            .then(async res => {
                if (res.ok) {
                    setPingData(await res.json());
                } else {
                    console.log(res);
                    setPingData(undefined);
                    setPingError(await res.text());
                }
            })
            .catch(err => {
                console.log(err);
                setPingData(undefined);
                setPingError(err.message);
            })
    }

    useEffect(() => {
        const interval = setInterval(() => {
            ping();
        }, 2000);

        return () => {
            clearInterval(interval);
        }
    }, []);

    return <div className={"p-4 min-h-screen w-full flex items-center justify-center"}>
        <ClayPanel>
            <div className={"p-4"}>
                <div className={"text-lg"}>欢迎使用 ClayCore 调试工具™</div>
                <div className={"text-sm opacity-60"}>Welcome to use ClayCore Debugger Tool™ V1.0</div>
                <Divider className={"my-4"}/>

                {pingData ? <div
                    className={"flex gap-2 items-center text-sm opacity-80 text-clay-green"}
                >
                    <div className={"w-2 h-2 bg-clay-green"}></div>
                    连接成功 - {pingData.player} ({pingData.version})
                </div> : (pingError ? <div
                    className={"flex gap-2 items-center text-sm opacity-80 text-clay-red"}
                    onClick={() => {
                        setPingError(undefined);
                        ping();
                    }}
                >
                    <div className={"w-2 h-2 bg-clay-red"}></div>
                    连接失败，请检测游戏是否开启，点击重新连接
                </div> : <div
                    className={"flex gap-2 items-center text-sm opacity-80"}
                >
                    <Icon icon="pixelarticons:sharp-corner"/>
                    正在连接...
                </div>)}

                <Divider className={"my-4"}/>
                <ClayInput value={unauthorizedPassword} onChange={str => {
                    setUnauthorizedPassword(str);
                }} placeholder={"验证密码"}/>
                <div className={"w-96 mt-4 text-sm opacity-40 px-2"}>
                    如果您在游戏中遇到了问题，需要先向管理员联系。如果确认需要使用本工具后，将管理员发送的密码输入且点击进入。
                </div>
                <Divider className={"my-4"}/>

                <ClayButton onClick={async () => {
                    if (authorizing) return;
                    setAuthorizing(true);
                    fetch("http://127.0.0.1:8323/login", {
                        headers: {
                            "Authorization": unauthorizedPassword
                        }
                    })
                        .then(async res => {
                            setAuthorizing(false);
                            if (res.ok) {
                                setPassword(unauthorizedPassword);
                            } else {
                                setAuthorizationError(await res.text());
                            }
                        })
                        .catch(err => {
                            setAuthorizing(false);
                            console.log(err);
                            setAuthorizationError(err.message);
                        })
                }} color={pingData && !authorizing && unauthorizedPassword ? "bg-clay-green" : "bg-clay-dark-gray"}
                            disabled={!pingData || authorizing || !unauthorizedPassword}>
                    {unauthorizedPassword ? (authorizing ? "验证中..." : "进入") : "请输入密码"}
                </ClayButton>
                {authorizationError && <div className={"w-full mt-4 text-center opacity-80 text-clay-red"}>
                    认证失败: {authorizationError}
                </div>}
            </div>
        </ClayPanel>
    </div>
}