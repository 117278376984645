import React from 'react';
import {Root} from "./component/page/Root";

function App() {
    return (
        <div className={"bg-page text-main clay-text-shadow"}>
            <div className={"min-h-screen content-padding"}>
                <Root />
            </div>
        </div>
    );
}

export default App;
